// @flow
import React from 'react';
import { FacebookOutlined, TwitterOutlined, LinkedinOutlined } from '@ant-design/icons';

import * as Styled from './styledComponents';

import { LogoPlotify } from '@project/assets/images';

const Footer = () => (
    <Styled.FooterWrapper>
        <Styled.PoweredByWrapper>
            <Styled.Logo alt="logo" src={LogoPlotify} />
            <Styled.PoweredBy>Powered by Plotify © {new Date().getFullYear()}</Styled.PoweredBy>
        </Styled.PoweredByWrapper>
        <Styled.SocialWrapper data-testid="social-group">
            <Styled.Link
                href="https://www.facebook.com/Plotify-1094333800707322/?ref=br_rs"
                rel="noopener noreferrer"
                target="_blank"
            >
                <FacebookOutlined />
            </Styled.Link>
            <Styled.Link
                href="https://twitter.com/Plotify1"
                rel="noopener noreferrer"
                target="_blank"
            >
                <TwitterOutlined />
            </Styled.Link>
            <Styled.Link
                href="https://www.linkedin.com/company/plotify-financial/"
                rel="noopener noreferrer"
                target="_blank"
            >
                <LinkedinOutlined />
            </Styled.Link>
        </Styled.SocialWrapper>
        <Styled.Website>
            To find out more about Plotify visit
            <a
                href="https://plotify.co.uk/"
                rel="noopener noreferrer"
                target="_blank"
                style={{ paddingLeft: 8 }}
            >
                www.plotify.co.uk
            </a>
        </Styled.Website>
    </Styled.FooterWrapper>
);

export default Footer;
