import styled from 'styled-components';

export const LoginPage = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f2f5;
`;

export const LoginForm = styled.div`
    min-width: 400px;
    min-height: 350px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 3px 3px 9px 0 rgba(0, 0, 0, 0.1);
`;

export const FooterWrapper = styled.div`
    width: 100%;
    position: fixed;
    bottom: 0;
`;
