// @flow
import React from 'react';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';

import ForgottenPasswordForm from '.';

import type { RouterHistory } from 'react-router-dom';

const MUTATION_RESET_PASSWORD = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input)
    }
`;

type Props = {
    history: RouterHistory;
};

const ForgottenPasswordFormGQL = ({ history }: Props) => (
    <Mutation mutation={MUTATION_RESET_PASSWORD}>
        {resetPassword => (
            <ForgottenPasswordForm
                history={history}
                onSubmit={values =>
                    resetPassword({
                        variables: { input: values },
                    })
                }
            />
        )}
    </Mutation>
);

export default ForgottenPasswordFormGQL;
