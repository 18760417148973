// @flow
import React, { Component } from 'react';
import { Formik } from 'formik';
import { Form, Button, message } from 'antd';
import { Link } from 'react-router-dom';

import FormFieldAdapter from '@project/components/FormFieldAdapter';

import * as Styled from './styledComponents';

import type { FormikBag } from 'formik';
import type { RouterHistory } from 'react-router-dom';

import { LogoWithTitle } from '@project/assets/images';
import { loadingMessage } from '@project/utils';
import logger from '@project/utils/logger';
import FIELDS from './fields';
import validationSchema from './validation';

const loadingMessageInstance = loadingMessage('Sending email...');

type Props = {
    onSubmit: Function;
    history: RouterHistory;

    initialValues?: GenericObject;
};

class ForgottenPasswordForm extends Component<Props> {
    static defaultProps = {
        initialValues: {
            email: '',
        },
    };

    onSubmit = async (values: Object, formikBag: FormikBag) => {
        const { onSubmit, history } = this.props;

        loadingMessageInstance.show();
        formikBag.setSubmitting(true);

        try {
            await onSubmit(values);

            message.success('Success, email sent.');
            history.push('/login');
        } catch (error) {
            logger.error('Something went wrong', error);
        } finally {
            loadingMessageInstance.hide();
            formikBag.setSubmitting(false);
        }
    };

    render() {
        const { initialValues } = this.props;

        return (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={this.onSubmit}
                validationSchema={validationSchema}
            >
                {formikProps => (
                    <Form onFinish={formikProps.handleSubmit}>
                        <Styled.Header>
                            <Styled.Logo alt="logo" src={LogoWithTitle} />
                            <Styled.Description>Reset your password</Styled.Description>
                        </Styled.Header>
                        <FormFieldAdapter field={FIELDS.email} formikProps={formikProps} />
                        <Form.Item
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                textDecoration: 'underline',
                            }}
                        >
                            <Link to="/login">Back to login</Link>
                        </Form.Item>
                        <Button
                            type="primary"
                            loading={formikProps.isSubmitting}
                            disabled={formikProps.isSubmitting}
                            htmlType="submit"
                            size="large"
                            style={{ width: '100%' }}
                            // onSubmit={this.onSubmit}
                        >
                            Send
                        </Button>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default ForgottenPasswordForm;
