// @flow
import React, { Component } from 'react';
import { Form } from 'antd';
import { Field } from 'formik';
import * as R from 'ramda';

import type { ComponentType } from 'react';

import '../FormikForm/styles.less';

const AntdFormItem = Form.Item;

type TGridSettings = {
    span?: number;
    offset?: number;
};

type TColSettings = {
    sm?: TGridSettings;
};

type Props = {
    // Formik Field Props
    // eslint-disable-next-line react/require-default-props
    field: {
        path: Array<any>;
        component: ComponentType<any>;
        // common Input Props
        label?: React.ReactNode;
        type?: string;
        placeholder?: string;
        disabled?: boolean;
        readOnly?: boolean;
        // Antd FormItem Props
        size?: 'large' | 'small';
        prefix?: React.ReactNode;
        required?: boolean;
        labelCol?: TColSettings;
        wrapperCol?: TColSettings;
        // Select Props
        showSearch?: boolean;
        // TextArea Props
        emptyRows?: number;
        // Our Custom Props
        format?: Function;
        normalize?: Function;
        customConfig?: GenericObject;
    };
    formikProps: GenericObject;
    skipShouldComponentDidUpdate?: boolean;
    aclDisabled?: boolean;

    children?: React.ReactNode;
    options?: Array<any>;
};

/**
 * Adapter between Formik and Antd Form Fields
 */
class FormFieldAdapter extends Component<Props> {
    static defaultProps = {
        field: {
            required: false,
            labelCol: {},
            wrapperCol: {},
            emptyRows: 0,
            placeholder: '',
            readOnly: false,
            customConfig: {},
        },
        children: null,
        options: null,
        aclDisabled: null,
        skipShouldComponentDidUpdate: false,
    };

    shouldComponentUpdate({
        field: { path, customConfig: prevCustomConfig },
        formikProps: { values: nextValues, touched: nextTouched, errors: nextErrors },
        options: nextOptions,
        aclDisabled: nextAclDisabled,
        skipShouldComponentDidUpdate,
    }: Props) {
        const {
            field: { customConfig },
            formikProps: { values, touched, errors },
            aclDisabled,
            options,
        } = this.props;

        if (skipShouldComponentDidUpdate) return true;

        if (aclDisabled !== nextAclDisabled) return true;

        if (Boolean(nextOptions) && !R.equals(options, nextOptions)) {
            // TODO: find some cleaner solution, which utilizes Input Component Type
            return true;
        }

        // ! Special condition to handle formik's late onBlur validation and skip this one render
        if (
            !R.path(path, touched) &&
            R.path(path, nextTouched) &&
            R.path(path, nextErrors) &&
            R.path(path, values) &&
            R.path(path, nextValues)
        ) {
            return false;
        }

        return (
            !R.equals(R.path(path, values), R.path(path, nextValues)) ||
            !R.equals(R.path(path, errors), R.path(path, nextErrors)) ||
            !R.equals(R.path(path, touched), R.path(path, nextTouched)) ||
            !R.equals(prevCustomConfig, customConfig)
        );
    }

    stringifyValidationMessage = (message: any): any => {
        if (!message) return '';

        // ! FIXME: find some better solution to handle nested Yup errors
        if (typeof message === 'object') {
            const [first] = Object.values(message);

            return typeof first === 'object' ? this.stringifyValidationMessage(first) : first;
        }

        return message;
    };

    render() {
        const {
            formikProps: { touched, errors },
            field: {
                path,
                label,
                type,
                placeholder,
                readOnly,
                disabled,
                component,
                size,
                prefix,
                required,
                labelCol,
                wrapperCol,
                showSearch,
                emptyRows,
                format,
                normalize,
                customConfig,
            },
            aclDisabled,
            options,
            children,
        } = this.props;

        const isTouched = R.path(path, touched);
        const error = R.path(path, errors);

        const isAlwaysTouched = R.path(['alwaysTouched'], customConfig);
        const validateStatus = (isTouched || isAlwaysTouched) && error ? 'error' : '';
        const help =
            (isTouched || isAlwaysTouched) && error ? this.stringifyValidationMessage(error) : '';

        return (
            <AntdFormItem
                required={required}
                label={label}
                disabled={disabled || aclDisabled}
                validateStatus={validateStatus}
                help={help}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
            >
                <Field
                    name={path.join('.')}
                    component={component}
                    type={type}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    disabled={disabled || aclDisabled}
                    format={format}
                    normalize={normalize}
                    size={size}
                    prefix={prefix}
                    showSearch={showSearch}
                    emptyRows={emptyRows}
                    options={options}
                    {...customConfig}
                >
                    {children}
                </Field>
            </AntdFormItem>
        );
    }
}

export default FormFieldAdapter;
