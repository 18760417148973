// @flow
import styled from 'styled-components';

export const ForgottenPasswordPage = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f2f5;
`;

export const ForgottenPasswordForm = styled.div`
    min-width: 400px;
    min-height: 275px;
    background-color: #ffffff;
`;

export const FooterWrapper = styled.div`
    width: 100%;
    position: fixed;
    bottom: 0;
`;
