// @flow
import * as Yup from 'yup';

import { ERROR_MESSAGES } from '@project/utils/constants';

const loginFormValidationSchema = Yup.object({
    email: Yup.string().typeError(ERROR_MESSAGES.INVALID_INPUT).required(ERROR_MESSAGES.REQUIRED),
    password: Yup.string()
        .typeError(ERROR_MESSAGES.INVALID_INPUT)
        .required(ERROR_MESSAGES.REQUIRED),
});

export default loginFormValidationSchema;
