// @flow
import React from 'react';

import ForgottenPasswordFormGQL from './Form/graphql';
import Footer from '@project/components/Footer';

import type { RouterHistory } from 'react-router-dom';

import * as Styled from './styledComponents';

type Props = {
    history: RouterHistory;
};

const ForgottenPassword = ({ history }: Props) => (
    <Styled.ForgottenPasswordPage>
        <Styled.ForgottenPasswordForm>
            <ForgottenPasswordFormGQL history={history} />
        </Styled.ForgottenPasswordForm>
        <Styled.FooterWrapper>
            <Footer />
        </Styled.FooterWrapper>
    </Styled.ForgottenPasswordPage>
);

export default ForgottenPassword;
