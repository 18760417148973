import React, { Component } from 'react';
import { message } from 'antd';

import LoginForm from './Form';
import Footer from '@project/components/Footer';
import { ForgottenPassword, Root, NotFound } from '../../routes';

import * as Styled from './styledComponents';

import Auth0Service, { LoginCredentials } from '../../services/Auth0';
import logger from '@project/utils/logger';

type Props = {
    onLogin: Function;
};

type State = {
    isLoading: boolean;
};

class LoginPage extends Component<Props, State> {
    state = {
        isLoading: false,
    };

    componentDidMount() {
        // we want to preload stuff on login page
        ForgottenPassword.preload();
        Root.preload();
        NotFound.preload();
    }

    handleSubmit = (values: LoginCredentials) => {
        const { onLogin } = this.props;

        this.setState({ isLoading: true });

        if (Auth0Service.login) {
            Auth0Service.login(values, async (result, error) => {
                if (error) {
                    logger.error(
                        `Authentication Error: ${error.description}`,
                        error,
                        logger.DISPLAY_MESSAGE
                    );
                } else {
                    message.success('Successfully logged in.');
                    await onLogin(result);
                }

                this.setState({ isLoading: false });
            });
        }
    };

    render() {
        const { isLoading } = this.state;

        return (
            <Styled.LoginPage>
                <Styled.LoginForm>
                    <LoginForm handleSubmit={this.handleSubmit} isLoading={isLoading} />
                </Styled.LoginForm>
                <Styled.FooterWrapper>
                    <Footer />
                </Styled.FooterWrapper>
            </Styled.LoginPage>
        );
    }
}

export default LoginPage;
