// @flow
import React, { Component } from 'react';
import { Formik } from 'formik';
import { Form, Button } from 'antd';
import { Link } from 'react-router-dom';

import FormFieldAdapter from '@project/components/FormFieldAdapter';

import * as Styled from './styledComponents';

import type { FormikBag } from 'formik';

import FIELDS from './fields';
import validationSchema from './validation';

import { LogoWithTitle } from '@project/assets/images';

type Props = {
    handleSubmit: Function;
    isLoading: boolean;

    initialValues?: GenericObject;
};

class LoginForm extends Component<Props> {
    static defaultProps = {
        initialValues: {
            email: '',
            password: '',
        },
    };

    onSubmit = (values: Object, formikBag: FormikBag) => {
        const { handleSubmit } = this.props;

        handleSubmit(values);
        formikBag.setSubmitting(false);
    };

    render() {
        const { isLoading, initialValues } = this.props;

        return (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={this.onSubmit}
                validationSchema={validationSchema}
            >
                {formikProps => (
                    <Form onFinish={formikProps.handleSubmit}>
                        <Styled.Header>
                            <Styled.Logo alt="logo" src={LogoWithTitle} />
                            <Styled.Description>Sign in to Admin Portal</Styled.Description>
                        </Styled.Header>
                        <FormFieldAdapter field={FIELDS.email} formikProps={formikProps} />
                        <FormFieldAdapter field={FIELDS.password} formikProps={formikProps} />
                        <Form.Item
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                textDecoration: 'underline',
                            }}
                        >
                            <Link to="/forgotten-password">Forgotten password</Link>
                        </Form.Item>
                        <Button
                            type="primary"
                            loading={formikProps.isSubmitting || isLoading}
                            htmlType="submit"
                            disabled={formikProps.isSubmitting || isLoading}
                            size="large"
                            style={{ width: '100%' }}
                        >
                            Login
                        </Button>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default LoginForm;
