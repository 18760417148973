// @flow
import React from 'react';
import { UserOutlined } from '@ant-design/icons';

import Input from '@project/components/FormikFields/Input';

const FIELDS = {
    email: {
        path: ['email'],
        placeholder: 'Your email',
        component: Input,
        size: 'large',
        prefix: <UserOutlined style={{ opacity: 0.65 }} />,
        required: true,
    },
};

export default FIELDS;
