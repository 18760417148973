// @flow
import React, { Component } from 'react';
import { Input as AntdInput } from 'antd';

import type { FieldProps } from 'formik';
import type { TFormikFieldProps } from '..';

type Props = FieldProps & TFormikFieldProps;

class Input extends Component<Props> {
    static defaultProps = {
        onChange: null,
        onBlur: null,
    };

    onChange = (event: GenericObject) => {
        const {
            field: { name },
            form: { setFieldValue },
            normalize,
        } = this.props;

        setFieldValue(name, normalize ? normalize(event.target.value) : event.target.value);
    };

    onBlur = (...args: any) => {
        const {
            field: { name },
            form: { setFieldTouched },
            onBlur,
        } = this.props;

        setFieldTouched(name, true);

        return onBlur && onBlur(...args);
    };

    render() {
        const {
            field: { name, value },
            form: _form,
            onBlur: _onBlur,
            onChange: _onChange,
            showSearch: _showSearch, // omit props
            emptyRows: _emptyRows, // omit props
            format,
            ...restProps
        } = this.props;

        return (
            <AntdInput
                name={name}
                value={format ? format(value) : value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                {...restProps}
            />
        );
    }
}

export default Input;
