// @flow
import styled from 'styled-components';

export const FooterWrapper = styled.div`
    background-color: #ffffff;
    height: 50px;
    padding: 0 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 900px) {
        justify-content: center;
        flex-direction: column;
        height: 120px;
        padding: 0;
    }
`;

export const PoweredByWrapper = styled.div`
    display: flex;
`;

export const Logo = styled.img`
    width: 36px;
    height: 18px;
`;

export const PoweredBy = styled.div`
    opacity: 0.65;
    color: #0e2b36;
    font-size: 12px;
    line-height: 22px;
    margin-left: 8px;
`;

export const SocialWrapper = styled.div`
    display: flex;
    position: relative;
    left: 45px;

    @media (max-width: 900px) {
        position: initial;
        padding: 10px 0;
    }
`;

export const Link = styled.a`
    margin: 0 15px;
    color: grey;

    &:hover {
        color: #a5cc40;
        cursor: pointer;
    }
`;

export const Website = styled.div`
    font-size: 12px;
    line-height: 22px;
`;
