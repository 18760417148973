// @flow
import styled from 'styled-components';

export const Header = styled.div`
    margin: 15px 0 30px 0;
    text-align: center;
`;

export const Logo = styled.img`
    width: 250px;
`;

export const Description = styled.div`
    display: flex;
    justify-content: center;
    height: 16px;
    opacity: 0.65;
`;
